



























































































import { Component, Vue, Watch } from 'vue-property-decorator'
import CouponPane from '@/components/marketing/coupon-pane.vue'
import { RequestPaging } from '@/utils/util'
import { apiCouponLists } from '@/api/marketing/coupon'
import DatePicker from '@/components/date-picker.vue'
import ExportData from '@/components/export-data/index.vue'
import { CouponType } from '@/utils/type'

@Component({
    components: {
        CouponPane,
        DatePicker,
        ExportData
    }
})
export default class Coupon extends Vue {
    apiCouponLists = apiCouponLists

    tabs = [
        {
            label: '全部',
            name: CouponType[0]
        },
        {
            label: '未开始',
            name: CouponType[1]
        },
        {
            label: '进行中',
            name: CouponType[2]
        },
        {
            label: '已结束',
            name: CouponType[3]
        }
    ]

    options = [
        { value: '', label: '全部' },
        { value: '1', label: '买家领取' },
        { value: '2', label: '卖家发放' }
    ]

    queryObj = {
        name: '',
        end_time: '',
        start_time: '',
        get_type: ''
    }
    lists = []
    tabCount = {
        all: 0, //全部
        not: 0, //未开始
        conduct: 0, //进行中
        end: 0 //已结束
    }
    pager = new RequestPaging()
    activeName: any = 'all'

    getList(page?: number): void {
        page && (this.pager.page = page)
        const status =
            CouponType[this.activeName] == '0'
                ? ''
                : CouponType[this.activeName]

        this.pager
            .request({
                callback: apiCouponLists,
                params: {
                    status: status,
                    ...this.queryObj
                }
            })
            .then(res => {
                this.tabCount = res?.extend
            })
    }
    resetQueryObj() {
        Object.keys(this.queryObj).map(key => {
            this.$set(this.queryObj, key, '')
        })
        this.getList()
    }
    created() {
        this.getList()
    }
}
