


























































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import PopoverInput from '@/components/popover-input.vue'
import {
    apiCouponDel, //删除优惠券
    apiCouponSort, //优惠券排序
    apiCouponOpen, //优惠券开始发放
    apiCouponStop //优惠券结束发放
} from '@/api/marketing/coupon'

@Component({
    components: {
        LsDialog,
        LsPagination,
        PopoverInput
    }
})
export default class couponPane extends Vue {
    $refs!: { paneTable: any }
    @Prop() value: any
    @Prop() pager!: any

    // 删除优惠券
    couponDel(ids: number) {
        apiCouponDel({
            id: ids
        }).then(() => {
            // this.$message.success('修改成功!')
            this.$emit('refresh')
        })
    }

    // 开始优惠券
    couponOpen(ids: number) {
        apiCouponOpen({
            id: ids
        }).then(() => {
            // this.$message.success('开启成功!')
            this.$emit('refresh')
        })
    }

    // 结束优惠券
    couponStop(ids: number) {
        apiCouponStop({
            id: ids
        }).then(() => {
            // this.$message.success('关闭成功!')
            this.$emit('refresh')
        })
    }

    // 优惠券排序
    couponSort(sort: string, id: number) {
        apiCouponSort({
            id,
            sort
        }).then(() => {
            this.$emit('refresh')
        })
    }
}
